export const adOnboardingSteps = [
  {
    path: "/laf-onboarding/step-1",
    header: "Step 1: Welcome to our Onboarding - Here's What To Expect 🤷🏻‍♂️",
    vimeoUrl: "https://player.vimeo.com/video/1029120130",
    secondaryHeader:
      "A Streamlined Process to Take the Second Step Towards Your Future Freedom",
    description:
      "Please Note: The on-boarding process takes around 90 minutes, please leave adequate time for this. This is the most efficient marketing on-boarding process you'll ever come across. \n\nIt may take 90 min, but once it's done... it's done. No back and forth emails or stumbling for passwords further down the line.",
    buttonText: "Take Me To The Next Step",
    buttonLink: "/laf-onboarding/step-2",
  },
  {
    path: "/laf-onboarding/step-2",
    header: "Step 2: What To Expect From The 90 Days 🏅",
    vimeoUrl: "https://player.vimeo.com/video/1038719167",
    description: "",
    buttonText: "Take Me To The Next Step",
    buttonLink: "/laf-onboarding/step-3",
  },
  {
    path: "/laf-onboarding/step-3",
    header: "Step 3: Sign Contract & Pay Invoice ⚡️",
    vimeoUrl: "https://player.vimeo.com/video/1029120250",
    description: "",
    secondaryHeader: "Sign the Contract",
    headlineCalloutText: "Here",
    headlineLink: "",
    buttonText: "Take Me To The Next Step",
    buttonLink: "/laf-onboarding/step-4",
  },
  {
    path: "/laf-onboarding/step-4",
    header: "Step 4: Time For Ads Manager Setup! 💥",
    vimeoUrl: "https://player.vimeo.com/video/1029855707",
    description: "",
    secondaryHeader: "Ad ID: 2499636336910980;",
    headlineCalloutText: "Start here",
    headlineLink: "https://business.facebook.com/latest/settings/",
    buttonText: "Take Me To The Next Step",
    buttonLink: "/laf-onboarding/step-5",
  },
  {
    path: "/laf-onboarding/step-5",
    header: "Step 5: Tell Us About Your Audience! 😎",
    vimeoUrl: "https://player.vimeo.com/video/1029120367",
    description:
      "This is probably the longest step, but bear with it! Remember: we are buying your future freedom and success! The more detail you can give us, the faster we can get there",
    secondaryHeader: "Tell Us About Your Audience: ",
    headlineCalloutText: "Here",
    headlineLink: "https://forms.gle/D6eXUeqYk9FK8irK8",
    buttonText: "Take Me To The Next Step",
    buttonLink: "/laf-onboarding/step-6",
  },
  {
    path: "/laf-onboarding/step-6",
    header: "Step 6: Book In Your Strategy Session 🙌",
    vimeoUrl: "https://player.vimeo.com/video/1029120494",
    description: "",
    secondaryHeader: "Book a Strategy Session ",
    headlineCalloutText: "Here",
    headlineLink:
      "https://api.leadconnectorhq.com/widget/booking/C5hynvWeD69534d7ut04",
    buttonText: "Take Me To The Next Step",
    buttonLink: "/laf-onboarding/step-7",
  },
  {
    path: "/laf-onboarding/step-7",
    header: "Step 7: Just Some Final Words ⭐️",
    vimeoUrl: "https://player.vimeo.com/video/1029119995",
    description: "",
    secondaryHeader:
      "Congratulations! You are done and can close out this page",
  },
];
